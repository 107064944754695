<template>
  <div id="submenu">
    <router-link :to="{ name: 'ContactUs', params: { topic: '-' } }" class="list-group-item" :class="{active: menu_list.contact}">Contact Us <i class="fa fa-chevron-right"></i></router-link>
    <router-link :to="{ name: 'RMARequest' }" class="list-group-item" :class="{active: menu_list.rma}">RMA Request <i class="fa fa-chevron-right"></i></router-link>
    <router-link :to="{ name: 'FAQ'}" class="list-group-item" :class="{active: menu_list.faq}">FAQ <i class="fa fa-chevron-right"></i></router-link>
    
    
  </div>
</template>


<script>
import { ref } from 'vue'

export default {
  props: [ 'menu' ],
  setup(props) {
    const menu_list = ref({
        faq: false,
        rma: false,
        contact: false
      })
      
    menu_list.value[props.menu] = true
     
    return { menu_list }
  }
}
</script>