<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-support menu="faq"></submenu-support>

    <!-- page content on the right -->
    <div class="submenu-content">
      <div class="accordion faq" id="accordionExample">
        
        <div>
          <p class="lead">Accounts</p>
          <div class="accordion-item" v-for="account in accounts" :key="account">
            <h2 class="accordion-header" :id="account[0]">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#'+ account[2]"
                aria-expanded="false"
              >
                {{account[0]}}
              </button>
            </h2>
            <div
              :id="account[2]"
              class="accordion-collapse collapse"
              :aria-labelledby="account[0]"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div>
                  <div class="subtitle2" v-html="account[1]"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div>
          <p class="lead">Contact</p>
          <div class="accordion-item" v-for="contact in contacts" :key="contact">
            <h2 class="accordion-header" :id="contact[0]">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#'+ contact[2]"
                aria-expanded="false"
              >
                {{contact[0]}}
              </button>
            </h2>
            <div
              :id="contact[2]"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="subtitle2" v-html="contact[1]"></div>
              </div>
            </div>
          </div>
        </div>
        
        <div>
          <p class="lead">Products</p>
          <div class="accordion-item"  v-for="product in products" :key="product">
            <h2 class="accordion-header" :id="product[0]">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#'+ product[2]"
                aria-expanded="false"
              >
                {{product[0]}}
              </button>
            </h2>
            <div
              :id="product[2]"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="subtitle2" v-html="product[1]"></div>
              </div>
            </div>
          </div>
        </div>
        
        <div>
          <p class="lead">Ordering</p>
          <div class="accordion-item" v-for="ordering in orderings" :key="ordering">
            <h2 class="accordion-header" :id="ordering[0]">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#'+ ordering[2]"
                aria-expanded="false"
              >
                {{ordering[0]}}
              </button>
            </h2>
            <div
              :id="ordering[2]"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="subtitle2" v-html="ordering[1]"></div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p class="lead">Returns (RMA) </p>
          <div class="accordion-item" v-for="RMA in returns" :key="RMA">
            <h2 class="accordion-header" :id="RMA[0]">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#'+ RMA[2]"
                aria-expanded="false"
              >
                {{RMA[0]}}
              </button>
            </h2>
            <div
              :id="RMA[2]"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">            
                  <div class="subtitle2" v-html="RMA[1]"></div>
              </div>
            </div>
          </div>
        </div>

        

      </div>
    </div>
  </div>
</template>

<script>
import SubmenuSupport from "../../components/nav/SubmenuSupport.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import { getAPI } from "../../utils/axios-api";
import { ref } from "vue";

export default {
  name: "FAQ",
  components: { SubmenuSupport, BreadcrumbPage },
  setup() {
    const accounts = ref([])
    const contacts = ref([])
    const products = ref([])
    const orderings = ref([])
    const returns = ref([])
    const error = ref(null);

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Support", link: ""})
    breadcrumb_links.value.push({text: "FAQ", link: "FAQ"})

    let url = "content/pages/55";
    getAPI
      .get(url)
      .then((response) => {
        if (response.status === 200) {  
          let outter = response.data.body;
          for (let outterIndex in outter) {
            let inner = outter[outterIndex].value
            for(let innerIndex in inner){
              if (inner[innerIndex].value.name == "account"){     
                accounts.value.push([inner[innerIndex].value.heading,inner[innerIndex].value.paragraph,'account'+innerIndex])
              }

              if (inner[innerIndex].value.name == "contact"){     
                contacts.value.push([inner[innerIndex].value.heading,inner[innerIndex].value.paragraph,'contact'+innerIndex])
              }

              if (inner[innerIndex].value.name == "product"){     
                products.value.push([inner[innerIndex].value.heading,inner[innerIndex].value.paragraph,'product'+innerIndex])
              }

              if (inner[innerIndex].value.name == "ordering"){     
                orderings.value.push([inner[innerIndex].value.heading,inner[innerIndex].value.paragraph,'ordering'+innerIndex])
              }

              if (inner[innerIndex].value.name == "return"){     
                returns.value.push([inner[innerIndex].value.heading,inner[innerIndex].value.paragraph,'return'+innerIndex])
              }
            }    
          }
        }
      })
      .catch((err) => {
        error.value = "No page Found";
      });
    return { accounts, contacts,products, orderings, returns,error, breadcrumb_links };
  },
};
</script>

<style scoped>
a {
  color: #555;
}
.lead {
  font-size: 26px;
  font-family: "Roboto";
  font-weight: 600;
  color: #555555;
}
body {
  min-width: 300px;
  color: #555555;
  font-family: Segoe, Arial;
  font-size: 16px;
}
img {
  vertical-align: middle;
}

.subtitle2{
  padding-left: 5%;
}
.faq{
  width:80%
}
.lead{
  font-style: bold;
  margin-top: 2%;
}
</style>
